import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import moment from 'moment';
import { WrappedStyled,
         HiddenInput,
         SnackbarStyled,
         MenuStyled } from './styles';
import { ContentContext } from '~/_context';
import { CircularProgress } from '@mui/material';
import ReactPlayer from 'react-player';
import { CgCoffee } from "react-icons/cg";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
/*
Neceisto un XLS de bocas de empresario columna dni + boca
*/



const BaristasContainer = () => {

  const { userData } = useContext(ContentContext);

  const [selectedVideo, setSelectedVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [ isLoadingVideo, setIsLoadingVideo ] = useState(false);
  const [ title, setTitle ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ showPresentation, setShowPresentation ] = useState(true);

  const [ expertos, setExpertos ] = useState(null);
  const [ user, setUser ] = useState(null);
  const [ videos, setVideos ] = useState(null);
  const [ bocas, setBocas ] = useState([]);
  const [ isOpen, setIsOpen ] = useState(false);
  const [ bocaSelected, setBocaSelected ] = useState();

  const [ fileName, setFileName ] = useState("");
  const [ fileSize, setFileSize ] = useState("");
  const [ startUploading, setStartUploading ] = useState(false);
  const [ consignaEtapa1, setConsignaEtapa1 ] = useState(false);
  const [ consignaEtapa2, setConsignaEtapa2 ] = useState(false);
  const [ consignaEtapa3, setConsignaEtapa3 ] = useState(false);
  const [ videoEtapa1, setVideoEtapa1 ] = useState(null);
  const [ videoEtapa2, setVideoEtapa2 ] = useState(null);
  const [ videoEtapa3, setVideoEtapa3 ] = useState(null);
  const [ videoVotoEtapa1, setVideoVotoEtapa1 ] = useState(null);
  const [ videoSelectedEtapa1, setVideoSelectedEtapa1 ] = useState(null);
  const [ openSnack, setOpenSnack ] = useState(false);
  const [ snackMessage, setSnackMessage ] = useState("");
  const [ snackKey, setSnackKey ] = useState("");
  const [ scope, setScope ] = useState(null);
  const [ canVote, setCanVote ] = useState(true);
  const [ ownVoteEtapa1, setOwnVoteEtapa1 ] = useState(null);
  const [ votos, setVotos ] = useState([]);
  const [ baristaSelected, setBaristaSelected ] = useState(null);
  const [ haveBarista, setHaveBarista ] = useState(null);
  const [ videoToDisapprove, setVideoToDisapprove ] = useState(null);
  const [ conditionsViewedEtapa1, setConditionsViewedEtapa1 ] = useState(false);
  const [ conditionsViewedEtapa3, setConditionsViewedEtapa3 ] = useState(false);
  const [ errorVideosize, setErrorVideoSize ] = useState("");
  const [ bocasAll, setBocasAll ] = useState([]);
  const [ filterBoca, setFilterBoca ] = useState("");
  const [ filterStatus, setFilterStatus ] = useState("");
  const [ videoEtapa1Upload, setVideoEtapa1Upload ] = useState(false);
  const [ videoEtapa3Upload, setVideoEtapa3Upload ] = useState(false);
  const [ isVoting, setIsVoting ] = useState(false);
  const [ whatVoting, setWhatVoting ] = useState("");

  const fileInputRef = useRef(null);
  
  const baseURL = "https://fabianhr.com/protagonistas/baristas";
  //const baseURL = "https://fabianhr.com/protagonistas/baristas/staging";
  //const baseURL = "https://baristas.local:8890";

  const handleButtonBrowserClick = () => {
    fileInputRef.current.click();
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      //console.log(`File size: ${fileSizeInMB.toFixed(2)} MB`);
      if(fileSizeInMB.toFixed(2) > 300){
        setErrorVideoSize(`El video que estas intentando cargar pesa ${fileSizeInMB.toFixed(2) + " mb"} recorda que el limite es de 300 mb.`);
      }else{
        setFileName(file.name);
        setFileSize(fileSizeInMB.toFixed(2) + " mb");
        setSelectedVideo(file);
      }
    }
    //console.log(event.target.files[0]);
    
  };

  let stage = 1;

  const handleUpload = (etapa) => {
    if (!selectedVideo) {
      return;
    }
    setStartUploading(true);
    setIsLoadingVideo(true);
    const formData = new FormData();
    formData.append('video', selectedVideo);
    formData.append('title', title);
    formData.append('description', description);
    

    axios.post('https://fabianhr.com/protagonistas/baristas/upload.php', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        //console.log("percentage", percentage)
        setUploadProgress(percentage);
      },
    })
      .then((response) => {
        setIsLoadingVideo(false);
        setUploadStatus('¡Video subido correctamente!');

        if("uri" in response.data){
          let uri = response.data.uri.split("/");

          let eess = "";
          if("pos" in userData){
            if(userData.pos.length){
              eess = userData.pos[0].code;
            }
          }

          const payload = { id: user.id, uri: uri[2], etapa: etapa, title: title, description: description, boca: eess };
          saveVideo(payload);
        }
      })
      .catch((error) => {
        setIsLoadingVideo(false);
        setUploadStatus('El video  no se pudo publicar, intentá nuevamente.');
        console.error(error);
        //setStartUploading(false);
      });
  };
  
  const handleSendVideo = () => {

  }

  const getUserData = useCallback(async (payload) => {
    console.log("payload", payload)
    const requestURL = `${baseURL}/data.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/data.php`;
    //const requestURL = `https://baristas.local:8890/data.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }
    /*
     console.log("filter_boca", filterBoca);
    if("filter_boca" in payload){
      params.append("filter_boca", payload.filter_boca);
    }

    if("filter_status" in payload){
      params.append("filter_status", payload.filter_status);
    }
    */
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);

  const setBarista = useCallback(async (payload) => {
    const requestURL = `${baseURL}/setBarista.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/setBarista.php`;
    //const requestURL = `https://baristas.local:8890/setBarista.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);

  const setVideoViewed = useCallback(async (payload) => {
    const requestURL = `${baseURL}/setVideoViewed.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/setVideoViewed.php`;
    //const requestURL = `https://baristas.local:8890/setVideoViewed.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);

  /*
  const setConditionsViewed = useCallback(async (payload) => {
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/setVideoViewed.php`;
    const requestURL = `https://baristas.local:8890/setVideoViewed.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);
  */
  
  const saveVideo = useCallback(async (payload) => {
    const requestURL = `${baseURL}/saveVideo.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/saveVideo.php`;
    //const requestURL = `https://baristas.local:8890/saveVideo.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);

  const saveApprove = useCallback(async (payload) => {
    const requestURL = `${baseURL}/saveApprove.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/saveApprove.php`;
    //const requestURL = `https://baristas.local:8890/saveApprove.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);

  const saveDisapprove = useCallback(async (payload) => {
    const requestURL = `${baseURL}/saveDisapprove.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/saveDisapprove.php`;
    //const requestURL = `https://baristas.local:8890/saveDisapprove.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);

  const saveVote = useCallback(async (payload) => {
    
    const requestURL = `${baseURL}/saveVote.php`;
    //const requestURL = `https://fabianhr.com/protagonistas/baristas/saveVote.php`;
    //const requestURL = `https://baristas.local:8890/saveVote.php`;
    const params = new URLSearchParams()

    if(Object.keys(payload).length){
        Object.keys(payload).map(function(key) {
            params.append(key, payload[key]);
        })
    }

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
    const result = await axios.post(requestURL, params, config);
    return result;

  }, []);
  
  

  /*
  const getUserData = useCallback(async (dni) => {
    const requestURL = `https://baristas.local:8890/data.php?dni=${dni}`;
    await axios.get(`${requestURL}`).then(result => { 
    console.log("result", result);
    }).catch(err => {
        //setHaveResponseError(true);
        //setHaveResponseErrorMessage(err.message);
    });
  }, []);
  */

  const handleSetBarista = (experto) => {
    
    setBaristaSelected(experto.id)
    //console.log("🚀", experto.id)
    
  }

  const handleSaveBarista = () => {
    
    const payload = {id: baristaSelected, boca: bocaSelected}
    //console.log("🔥", payload)

    
    const barista_setted = setBarista(payload);
    barista_setted.then(res => {

      setSnackKey(uuidv4());
      setSnackMessage("El barista se seleccionó con éxito");
      setOpenSnack(true);
      
      const bocas_ = [...bocas];
      const bocasUpdater = bocas_.map(boca => {
        if(boca.value === bocaSelected){
          const experto = boca.expertos.map(exp => {
            
            if(exp.id === baristaSelected){
              setHaveBarista(exp);
              setExpertos(null);
              exp.is_barista = true;
            }else{
              exp.is_barista = false;
            }
          })
          //console.log("🚀", experto)
        }
        return boca;
      })
      //console.log("🔥", bocasUpdater)
      setBocas(bocasUpdater);

    })
    
  }

  const handleBoca = (e) => {
    if(e.target.value){
      const exp = bocas.filter(boca => boca.value === e.target.value);
      if(exp.length){
        const have_barista = exp[0].expertos.filter(exp => exp.is_barista === 1);
        //console.log("have_barista", have_barista, exp[0].expertos)
        if(have_barista.length){
          setExpertos(null);
          setHaveBarista(have_barista[0]);
        }else{
          setHaveBarista(null);
          setExpertos(exp[0].expertos);
        }
      }
    }else{
      setExpertos(null);
    }
    setBocaSelected(e.target.value);
  }

  const handleStart = () => {
    setShowPresentation(false);
  }
  





  const [ onVideoEnded, setOnVideoEnded ] = useState(false);
  const _player = useRef(null);

  const handleOnReady = (e) => {

  }
  const handleVideoSeek = (e) => {
  }

  const handleVideoStop = () => {

  }

  const handleVideoStart = () => {

  }

  const handleVideoPlay = () => {
    
  }

  const handleVideoProgress = (progress, etapa) => {
    console.log(progress.played);
    if(!onVideoEnded && progress.played >= 0.95){
      setOnVideoEnded(true);
      if(etapa === "etapa1"){
        const payload = {id: user.id, etapa: etapa};
        const response = setVideoViewed(payload);
        response.then(res => {
          setConsignaEtapa1(true);
        })
      }
    };
  }
  const handleVideoEnded = () => {

  }

  const handleApprove = (video_id, etapa) => {
    const payload = {video_id: video_id, etapa: etapa};
    const response = saveApprove(payload);
    response.then(res => {

      setSnackKey(uuidv4());
      setSnackMessage("El video se aprobó con éxito");
      setOpenSnack(true);

      const videos_update = [...videos];
      const videos_update_ = videos_update.map(video => {
        if(video.id === video_id){
          if(etapa === "etapa1"){
            video.arrpove_etapa1 = true;
          }
          if(etapa === "etapa2"){
            video.arrpove_etapa2 = true;
          }
          if(etapa === "etapa3"){
            video.arrpove_etapa3 = true;
          }
        }
        return video;
      })
      setVideos(videos_update_);
    })
  }

  const handleDisapprove = (etapa, reason) => {
    //console.log("video_id", videoToDisapprove, reason)
    
    setAnchorEl(null);
    const payload = {video_id: videoToDisapprove, etapa: etapa, reason: reason};
    //console.log("payload", payload);
    //return null;
    const response = saveDisapprove(payload);
    response.then(res => {

      setSnackKey(uuidv4());
      setSnackMessage("El video se desaprobó con éxito");
      setOpenSnack(true);

      const videos_update = [...videos];
      const videos_update_ = videos_update.map(video => {
        if(video.id === videoToDisapprove){
          if(etapa === "etapa1"){
            video.reason_etapa1 = reason;
            video.arrpove_etapa1 = false;
          }
          if(etapa === "etapa2"){
            video.reason_etapa2 = reason;
            video.arrpove_etapa2 = false;
          }
          if(etapa === "etapa3"){
            video.reason_etapa3 = reason;
            video.arrpove_etapa3 = false;
          }
        }
        return video;
      })
      console.log("videos_update_", videos_update_)
      setVideos(videos_update_);
    })
  }

  const handleSnackClose = () => {

    setOpenSnack(false);

  }

  const handleVote = (video_id, etapa, user_dni) => {

    setWhatVoting(video_id);
    setIsVoting(true);
    //return null;
    
    const payload = {video_id: video_id, etapa: etapa, dni: user_dni};
    const response = saveVote(payload);
    response.then(res => {

      setSnackKey(uuidv4());
      setSnackMessage("Tu voto se guardó con éxito");
      setOpenSnack(true);

      setOwnVoteEtapa1(video_id);
      setCanVote(false);
      setVotos(res.data.votos);

    })

  }

  const getVotos = (video_id) => {

    return votos[video_id] ? votos[video_id] : 0;

  }

  const handleDownloadPDF = (pdf) => {

    switch (pdf) {
      case "condiciones":
        /*
        const payload = {id: user.id, etapa: etapa};
        const response = setConditionsViewed(payload);
        response.then(res => {
          setConditionsViewedEtapa1(true);
        })
        */
        setConditionsViewedEtapa1(true);
        window.open('pdfs/Copa_Barista_Instructivo_Desarrollo_Video_Fase1.pdf', '_blank').focus();
        break;
      case "instructivo":
        window.open('pdfs/Instructivo_Competencia_Copa_Barista_2608_2024.pdf', '_blank').focus();
        break;
      case "reglamento":
        window.open('pdfs/2024-08-19-Reglamento-COPA_BARISTA.pdf', '_blank').focus();
        break;
      case "faq":
        window.open('pdfs/Copa_Barista_Preguntas_Frecuentes.pdf', '_blank').focus();
        break;
      case "etapa2":
        setConditionsViewedEtapa1(true);
        window.open('pdfs/Instructivo_Video_Fase_2.pdf', '_blank').focus();
        break;
      case "etapa3":
        setConditionsViewedEtapa3(true);
        window.open('pdfs/Instructivo_Video_Fase_3.pdf', '_blank').focus();
        break;
      default:
        break;
    }

  }

  const handleChangeFilter = (e, name) => {
    console.log(name, e.currentTarget.value)
    switch (name) {
      case "status":
        setFilterStatus(e.currentTarget.value);
        break;
      case "boca":
        setFilterBoca(e.currentTarget.value);
        break;
      default:
        break;
    }
    
  }

  const handleFilter = () => {

    const payload = {dni: userData.dni, filter_boca: filterBoca, filter_status: filterStatus}
    const response = getUserData(payload);
    response.then(res => {
      setVideos(res.data.videos);
    })
    //getUserData(payload);

  }



  useEffect(() => {
    
    if(userData){
      if(Object.keys(userData).length){
        const payload = {dni: userData.dni}
        const response = getUserData(payload);
        response.then(res => {
          setScope(res.data.scope);
          setUser(res.data.user);
          setIsOpen(true);
          setVideoEtapa1Upload(res.data.video_etapa1_upload);
          if(res.data.user.role === "empresario"){
            //setExpertos(res.data.expertos);
            let arr_bocas = [{value: "", label: "Seleccioná una boca..."}];
            res.data.bocas.map(boca => {
              arr_bocas.push({value: boca.boca, label: boca.boca + " - " + boca.razon, expertos: boca.expertos});
            })
            
            //setIsOpen(true);
            setBocas(arr_bocas);
          }
          if(res.data.user.role === "experto"){

            //setIsOpen(true);
            //setIsOpen(res.data.is_open);

            if(res.data.user.is_barista){
              
              setConsignaEtapa1(res.data.user.consigna_etapa1);
              setConsignaEtapa2(res.data.user.consigna_etapa2);
              setVideoEtapa1(res.data.user.videp_etapa1 ? Object.keys(res.data.user.videp_etapa1).length ? res.data.user.videp_etapa1 : null : null);
              setVideoEtapa2(res.data.user.videp_etapa2 ? Object.keys(res.data.user.videp_etapa2).length ? res.data.user.videp_etapa2 : null : null);
              setConditionsViewedEtapa1(res.data.user.videp_etapa1 ? true : false);
              let eess = "";
              let eess_razon = "";
              if("pos" in userData){
                if(userData.pos.length){
                  eess = userData.pos[0].code;
                  eess_razon = userData.pos[0].name;
                }
              }

              let user_name = "";
              let user_name_arr = [];

              if(userData.name){
                user_name_arr.push(userData.name);
              }
              if(userData.last_name){
                user_name_arr.push(userData.last_name);
              }
              if(user_name_arr.length > 1){
                user_name = user_name_arr.join(" ");
              }

              setTitle(`EESS ${eess} - ${eess_razon}` )
              setDescription(`${user_name ? user_name + " - " : ""}DNI ${userData.dni ? userData.dni : ""}`);
              //setDescription(`Copa Baristas - Etapa 1 - EESS ${eess} - ${eess_razon} - DNI ${userData.dni}`);

            }
          }

          if(res.data.user.is_selected){
            console.log("res.data.user", res.data.user)
            setConsignaEtapa1(res.data.user.consigna_etapa2);
            setVideoEtapa1(res.data.user.videp_etapa2 ? Object.keys(res.data.user.videp_etapa2).length ? res.data.user.videp_etapa2 : null : null);
            setConditionsViewedEtapa1(res.data.user.videp_etapa2 ? true : false);
            let eess = "";
              let eess_razon = "";
              if("pos" in userData){
                if(userData.pos.length){
                  eess = userData.pos[0].code;
                  eess_razon = userData.pos[0].name;
                }
              }

              let user_name = "";
              let user_name_arr = [];

              if(userData.name){
                user_name_arr.push(userData.name);
              }
              if(userData.last_name){
                user_name_arr.push(userData.last_name);
              }
              if(user_name_arr.length > 1){
                user_name = user_name_arr.join(" ");
              }

              setTitle(`EESS ${eess} - ${eess_razon}` )
              setDescription(`${user_name ? user_name + " - " : ""}DNI ${userData.dni ? userData.dni : ""}`);
          }

          if(res.data.user.is_winner){

            setConsignaEtapa3(res.data.user.consigna_etapa3);
            setVideoEtapa3(res.data.user.videp_etapa3 ? Object.keys(res.data.user.videp_etapa3).length ? res.data.user.videp_etapa3 : null : null);
            setConditionsViewedEtapa3(res.data.user.videp_etapa3 ? true : false);
            setVideoEtapa3Upload(res.data.video_etapa3_upload);
            let eess = "";
              let eess_razon = "";
              if("pos" in userData){
                if(userData.pos.length){
                  eess = userData.pos[0].code;
                  eess_razon = userData.pos[0].name;
                }
              }

              let user_name = "";
              let user_name_arr = [];

              if(userData.name){
                user_name_arr.push(userData.name);
              }
              if(userData.last_name){
                user_name_arr.push(userData.last_name);
              }
              if(user_name_arr.length > 1){
                user_name = user_name_arr.join(" ");
              }

              setTitle(`EESS ${eess} - ${eess_razon}` )
              setDescription(`${user_name ? user_name + " - " : ""}DNI ${userData.dni ? userData.dni : ""}`);

          }

          if(res.data.user.role === "shell"){
            //setIsOpen(true);
            console.log("res.data.videos", res.data.videos)
            setVideos(res.data.videos);
            setBocasAll(res.data.bocas_all);
          }
          if(res.data.scope === "vote"){
            //setIsOpen(true);
            setVideoVotoEtapa1(res.data.videos);
            if(res.data.user.role === "shell"){
              setCanVote(false);
            }else{
              setCanVote(!res.data.own_vote ? true : false);
            }
            setOwnVoteEtapa1(res.data.own_vote ? res.data.own_vote : null);
          }
          if(res.data.scope === "etapa2"){
            setVideoSelectedEtapa1(res.data.videos);
          }

          if(res.data.scope === "etapa3"){
            setVideoSelectedEtapa1(res.data.videos);
          }
          
          setVotos(res.data.votos);
        })
        
        
      }
    }
    return () => {}
  }, [userData])
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, video_id) => {
    setVideoToDisapprove(video_id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCanVote = (canVote, videoEtapa1, video_id) => {
    if(videoEtapa1 === null){
      return canVote;
    }else{
      return false;
      /*
      if(videoEtapa1.id !== video_id){
        return true;
      }else{
        return false;
      }
      */
    }
    //canVote
    //(videoEtapa1 !== null && videoEtapa1.id !== video.id)

  }

  useEffect(() => {
    console.log("videoEtapa1", videoEtapa1)
  
    return () => {}
  }, [videoEtapa1])
  

  if(!user || scope === null || !isOpen){
    return null;
  }

  return (
    <WrappedStyled>
      
      <MenuStyled
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleDisapprove("etapa3", "especificaciones")}>Especificaciones</MenuItem>
        <MenuItem onClick={() => handleDisapprove("etapa3", "consigna")}>Consigna</MenuItem>
        <MenuItem onClick={() => handleDisapprove("etapa3", "ambas")}>Ambas</MenuItem>
      </MenuStyled>

      <SnackbarStyled
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnack}
        onClose={handleSnackClose}
        message={snackMessage}
        key={snackKey}
        autoHideDuration={5000}
        className="success"
      />

      <div className='baristas_lineas'>
        <img src='images/baristas_lineas.svg' />
      </div>
      <div className='baristas_lineas_r'>
        <img src='images/baristas_lineas_r.svg' />
      </div>

      <div className='logo_top'>
        <img src='images/baristas_logo.svg' /> 
      </div>

      {showPresentation ? 
        <div className='box_welcome'>
          {/* <div className='welcome_text'>
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less letters.
          </div>  */}
          <div className='welcome_video'>
            <ReactPlayer
              ref={_player}
              className={`react-player`}
              playing={false}
              url={"https://vimeo.com/1009884865"}
              width='100%'
              height='100%'
              controls={true}
              onProgress={(progress) => { handleVideoProgress(progress)}}
              onReady={handleOnReady}
              onPlay={handleVideoPlay}
              onStart={handleVideoStart}
              onStop={handleVideoStop}
              onEnded={handleVideoEnded}
              onSeek={handleVideoSeek}
              config={{ 
                file: {
                  attributes: {
                    controlsList: 'nodownload'  //<- this is the important bit
                  }
                },
                vimeo: {
                  playerOptions: {
                    volume: true,
                    interactive_markers: false,
                    interactive_params: false,
                    vimeo_logo: false,
                    play_button_position: "center",
                    //progress_bar: true,
                    title: false,
                    responsive: true
                  }
                }
              }}
            />
          </div>
          <div className='actions'>
            <div className='bt_gray' onClick={handleStart}>
              <p>Ingresar</p>
            </div>
          </div>
        </div>
      : "" }

      {!showPresentation && (user.role === "experto" || user.role === "empresario") && scope === "etapa3" && !user.is_winner ?
        //user.role !== "shell" ?
        <div className='box_voting'>
          <div className='inner'>
            
            <div className='intro_text'>¡YA ESTAMOS EN LA RECTA FINAL DE LA COPA BARISTA 2024!</div>
            <div className='intro_text2'>Muy pronto conoceremos las prácticas que están en camino a ser las ganadoras.</div>
            <div className='box_video_list'>
            
                {/* {videoSelectedEtapa1 === null ? <>cargando</> : ""}
                {videoSelectedEtapa1 !== null && videoSelectedEtapa1.length ?
                <div className='video_elements'>
                  {videoSelectedEtapa1.map(video => {
                    return(
                      <div className='video_element'>
                        <div className='video'>
                          <ReactPlayer
                            ref={_player}
                            className={`react-player`}
                            playing={false}
                            url={`https://vimeo.com/${video.video_id}`}
                            width='100%'
                            height='100%'
                            controls={true}
                            onProgress={(progress) => { handleVideoProgress(progress)}}
                            onReady={handleOnReady}
                            onPlay={handleVideoPlay}
                            onStart={handleVideoStart}
                            onStop={handleVideoStop}
                            onEnded={handleVideoEnded}
                            onSeek={handleVideoSeek}
                            config={{ 
                              file: {
                                attributes: {
                                  controlsList: 'nodownload'  //<- this is the important bit
                                }
                              },
                              vimeo: {
                                playerOptions: {
                                  controls: true,
                                  interactive_markers: false,
                                  interactive_params: false,
                                  vimeo_logo: false,
                                  play_button_position: "center",
                                  progress_bar: true,
                                  title: false,
                                  //responsive: true
                                }
                              }
                            }}
                          />
                        </div>
                        <div className='box_video_data'>
                          <div className='title'>{video.title}</div>
                          <div className='description'>{video.description}</div>
                        </div>
                      </div>
                    )
                  })}
                  </div>
                : 
                  <div className='novideos'>No hay videos para mostrar</div>
                } */}
              
            </div>
          </div>
        </div>
      : ""}


      {!showPresentation && scope === "vote" ?
        //user.role !== "shell" ?
        <div className='box_voting'>
          <div className='inner'>
            <div className='intro_text'>¡CONOCÉ A LOS BARISTAS QUE PASAN A LA SEGUNDA FASE DE LA COMPETICIÓN!</div>
            {/* <div className='title'>SHELL</div> */}
            <div className='box_video_list'>
              
                {videoVotoEtapa1 === null ? <>cargando</> : ""}
                {videoVotoEtapa1 !== null && videoVotoEtapa1.length ?
                <div className='video_elements'>
                  {videoVotoEtapa1.map(video => {
                    return(
                      <div className='video_element'>
                        <div className='video'>
                          <ReactPlayer
                            ref={_player}
                            className={`react-player`}
                            playing={false}
                            url={`https://vimeo.com/${video.video_id}`}
                            width='100%'
                            height='100%'
                            controls={true}
                            onProgress={(progress) => { handleVideoProgress(progress)}}
                            onReady={handleOnReady}
                            onPlay={handleVideoPlay}
                            onStart={handleVideoStart}
                            onStop={handleVideoStop}
                            onEnded={handleVideoEnded}
                            onSeek={handleVideoSeek}
                            config={{ 
                              file: {
                                attributes: {
                                  controlsList: 'nodownload'  //<- this is the important bit
                                }
                              },
                              vimeo: {
                                playerOptions: {
                                  controls: true,
                                  interactive_markers: false,
                                  interactive_params: false,
                                  vimeo_logo: false,
                                  play_button_position: "center",
                                  progress_bar: true,
                                  title: false,
                                  //responsive: true
                                }
                              }
                            }}
                          />
                        </div>
                        <div className='box_video_data'>
                          <div className='title'>{video.title}</div>
                          <div className='description'>{video.description}</div>
                        </div>
                        {/*
                        <div className='data'>
                          <div className='votes_counter'>
                            <div className='label'>Votos</div>
                            <div className='value'>{getVotos(video.id)}</div>
                          </div>
                          {(ownVoteEtapa1 === video.id) ?
                            <div className='vote_check'>
                              <IoCheckmarkDoneSharp />
                            </div>
                          : ""
                          // <div className='actions'>
                          //   {handleCanVote(canVote, videoEtapa1, video.id) ?
                          //   <>
                          //     {isVoting ?
                          //       whatVoting === video.id ?
                          //       <div className='loading'><CircularProgress size={30} /></div>
                          //       : ""
                          //     :
                          //       <div className='bt_skyblue bt_approve' onClick={() => !isVoting && handleVote(video.id, "etapa1", user.dni)}>
                          //         <p>VOTAR</p>
                          //       </div> 
                          //     }
                          //   </>
                          //   : "" }
                          // </div>
                          }
                        </div>
                        */}
                      </div>
                    )
                  })}
                  </div>
                : 
                  <div className='novideos'>No hay videos para mostrar</div>
                }
              
            </div>
          </div>
          {/*
          <div className='actions'>
            <div className='bt_gray bt_instructivo' onClick={() => handleDownloadPDF("instructivo")}>
              <p>Instructivo</p>
            </div>
            <div className='bt_skyblue bt_reglamento' onClick={() => handleDownloadPDF("reglamento")}>
              <p>Reglamento</p>
            </div>
            <div className='bt_gray bt_faq' onClick={() => handleDownloadPDF("faq")}>
              <p>Preguntas Frecuentes</p>
            </div>
          </div>
          */}
        </div>
      //   :

      //   <div className='box_voting_pending'>
      //   {/* <div className='voting_pending_text'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</div> */}
      //   <div className='inner'>
      //     <div className='text'>
      //       Estamos en la etapa de votación
      //     </div>
      //   </div>
      //   <div className='actions'>
      //       <div className='bt_gray bt_instructivo' onClick={() => handleDownloadPDF("instructivo")}>
      //         <p>Instructivo</p>
      //       </div>
      //       <div className='bt_skyblue bt_reglamento' onClick={() => handleDownloadPDF("reglamento")}>
      //         <p>Reglamento</p>
      //       </div>
      //       <div className='bt_gray bt_faq' onClick={() => handleDownloadPDF("faq")}>
      //         <p>Preguntas Frecuentes</p>
      //       </div>
      //     </div>
      // </div>

        // <div className='box_voting'>
        //   <div className=''>Estamos en la etapa de votación</div>
        // </div>
      : ""}

      {!showPresentation && user.role === "shell" /*&& scope !== "vote"*/ && scope === "etapa3" ?
      <div className='box_approve_video'>
        <div className='inner'>
          <div className='intro_text'>Una vez aprobado o desaprobado, el video no podrá ser modificado. Por favor, asegúrese de su decisión antes de proceder.</div>
          {/* <div className='title'>SHELL</div> */}

          <div className='filter_videos'>
            <div className='select_bocas'>
              <label>EESS:</label>
              <select onChange={(e) => handleChangeFilter(e, "boca")}>
                <option value="">Seleccionar una opción</option>
                {bocasAll.map(boca => {
                  return(
                    <option value={boca.boca}>{`${boca.boca} - ${boca.razon}`}</option>
                  )
                })}
              </select>
            </div>
            <div className='select_status'>
              <label>Estado:</label>
              <select onChange={(e) => handleChangeFilter(e, "status")}>
                <option value="">Seleccionar una opción</option>
                <option value="approved">Aprobado</option>
                <option value="disapproved">Desaprobado</option>
                <option value="pending">Pendiente</option>
              </select>
            </div>
            <div className='bt_skyblue bt_filter' onClick={handleFilter}>
              <p>Filtrar</p>
            </div>
          </div>

          <div className='box_video_list'>
            
              {videos === null ? <>cargando</> : ""}
              {videos !== null && videos.length ?
              <div className='video_elements'>
                {videos.map(video => {
                  return(
                    <div className='video_element'>
                      <div className='video'>
                        <ReactPlayer
                          ref={_player}
                          className={`react-player`}
                          playing={false}
                          url={`https://vimeo.com/${video.video_id}`}
                          width='100%'
                          height='100%'
                          controls={true}
                          onProgress={(progress) => { handleVideoProgress(progress)}}
                          onReady={handleOnReady}
                          onPlay={handleVideoPlay}
                          onStart={handleVideoStart}
                          onStop={handleVideoStop}
                          onEnded={handleVideoEnded}
                          onSeek={handleVideoSeek}
                          config={{ 
                            file: {
                              attributes: {
                                controlsList: 'nodownload'  //<- this is the important bit
                              }
                            },
                            vimeo: {
                              playerOptions: {
                                interactive_markers: false,
                                interactive_params: false,
                                vimeo_logo: false,
                                play_button_position: "center",
                                progress_bar: true,
                                title: false,
                                //responsive: true
                              }
                            }
                          }}
                        />
                      </div>
                      <div className='data'>
                        <div className='title'>{video.title}</div>
                        <div className='description'>{video.description}</div>

                        {!video.arrpove_etapa3 && !video?.reason_etapa3?.length ? 
                        <div className='actions'>
                            
                            <div className='bt_skyblue bt_approve' onClick={() => handleApprove(video.id, "etapa3")}>
                              <p>APROBAR</p>
                            </div>
                            
                            <div className='bt_gray bt_disapprove' id="basic-button" onClick={(e) => handleClick(e, video.id)}>
                              <p>DESAPROBAR</p>
                            </div>
      
                        </div>
                        :
                        video.arrpove_etapa3 ? 
                        <div className='box_shell_approve'>Aprobado</div>
                        :
                        <div className='box_shell_disapprove'>
                          {video.reason_etapa3 === "especificaciones" ? "No cumple con las especificaciones técnicas." : ""}
                          {video.reason_etapa3 === "consigna" ? "No cumple con la consigna." : ""}
                          {video.reason_etapa3 === "ambas" ? "No cumple con las especificaciones técnicas ni con la consigna indicada." : ""}
                        </div>
                        }
                        
                      </div>
                    </div>
                  )
                })}
                </div>
              : 
               <div className='novideos'>No hay videos para mostrar</div>
              }
          </div>
        </div>
        <div className='actions'>
            <div className='bt_gray bt_instructivo' onClick={() => handleDownloadPDF("instructivo")}>
              <p>Instructivo</p>
            </div>
            <div className='bt_skyblue bt_reglamento' onClick={() => handleDownloadPDF("reglamento")}>
              <p>Reglamento</p>
            </div>
            <div className='bt_gray bt_faq' onClick={() => handleDownloadPDF("faq")}>
              <p>Preguntas Frecuentes</p>
            </div>
          </div>
      </div>
      :
      ""}

      {/*!showPresentation && user.role === "empresario" && scope !== "vote" ?
      <div className='box_select_barista'>
        <div className='select_barista_text'>
        ¡Bienvenidos a la inscripción de la Copa Barista 2024!<br/>
        Le solicitamos que revise el instructivo antes de seleccionar al Experto concursante.<br/>
        Si no puede visualizar al Experto, por favor envíe un correo a contacto@somosprotagonistas.com.ar.

        </div> 

        <div className='actions'>
          <div className='bt_gray bt_instructivo' onClick={() => handleDownloadPDF("instructivo")}>
            <p>Instructivo</p>
          </div>
          <div className='bt_skyblue bt_reglamento' onClick={() => handleDownloadPDF("reglamento")}>
            <p>Reglamento</p>
          </div>
          <div className='bt_gray bt_faq' onClick={() => handleDownloadPDF("faq")}>
            <p>Preguntas Frecuentes</p>
          </div>
        </div>

        <div className='select_barista_inner'>
          <div className='title'>Seleccioná una boca</div>
          <div className='select_boca'>
            <select onChange={handleBoca}>
              {bocas?.map(boca => {
                return(
                  <option value={boca.value}>{boca.label}</option>
                )
              })}
              
            </select>
          </div>
          {haveBarista ? 
          <div className='barista_selected'>
            <div className='label'>Barista seleccionado</div>
            <div className='value'>{`${haveBarista.name} ${haveBarista.last_name}`}</div>
          </div>
          :
          <div className='select_barista'>
            {expertos?.map(experto => {
              return(
                <div className='barista_item'>
                  <div className='name'><p>{`${experto.name} ${experto.last_name}`}</p></div>
                  <div className='actions'>
                    <div className={`bt_barista_check${baristaSelected === experto.id ? " selected" : ""}`} onClick={() => handleSetBarista(experto)}>
                      {baristaSelected === experto.id ? <div className=''><IoCheckmarkDoneSharp /></div> : ""}
                    </div>
                  </div>
                </div>
              )
            })}
            {expertos ?
            <div onClick={handleSaveBarista} className={`bt_gray bt_save_barista${!selectedVideo ? " disabled" : "" }`}>
              <p>Aceptar</p>
            </div> : ""}
          </div> 
          }
        </div> 
      </div>
      : ""*/}





      {!showPresentation && user.role === "experto" && scope !== "vote" && scope === "etapa3" ?
        user.is_winner ?
        <div className='box_upload_video_etapa1'>
          
          <div className='upload_video_text'>¡BIENVENIDOS BARISTAS A LA FASE FINAL DE LA COMPETENCIA!</div>
          {/* <div className='upload_video_text'>¡Bienvenidos Baristas a la Fase 3 de la Competencia!</div> */}

          <div className='actions'>
            <div className='bt_gray bt_instructivo' onClick={() => handleDownloadPDF("instructivo")}>
              <p>Instructivo</p>
            </div>
            <div className='bt_skyblue bt_reglamento' onClick={() => handleDownloadPDF("reglamento")}>
              <p>Reglamento</p>
            </div>
            <div className='bt_gray bt_faq' onClick={() => handleDownloadPDF("faq")}>
              <p>Preguntas Frecuentes</p>
            </div>
          </div>

          <div className='inner'>

            <div className='ficha'>Subí tu video y envía la receta de tu creación por mail a contacto@somosprotagonistas.com.ar para completar tu participación en esta fase final.</div>

            {!conditionsViewedEtapa3 && videoEtapa3Upload === true ?
            <div className='video_block2'>
              
              <div className='bt_condiciones' onClick={() => handleDownloadPDF("etapa3")}>¡Preparate leyendo las condiciones para armar tu tercer video!</div>
            </div> : ""}


            <div className='box_uploader'>
              
              {/* {videoEtapa1Upload === false ? <>La etapa de participación ha finalizado</> : ""} */}

              {(!startUploading && !videoEtapa3 && videoEtapa3Upload) ? 
              <>
              <HiddenInput type="file" name="video" ref={fileInputRef} onChange={handleVideoChange} />
              <div onClick={() => {conditionsViewedEtapa3 && handleButtonBrowserClick()}} className={`bt_skyblue bt_select_file${!conditionsViewedEtapa3 ? " disabled" : ""}`}>
                <p>SELECCIONAR VIDEO</p>
              </div>
              </>
              : ""}

              {errorVideosize.length ? <div className='error_video_size'>{errorVideosize}</div> : ""}

              {videoEtapa3 ?
                <div className='box_video_loaded1'>
                  <div className='title'>Video Fase 3 publicado</div>
                  <div className='date'>{moment(videoEtapa3.created_at).format("DD/MM/yyyy HH:mm:ss")}</div>
                  {/* <div className='ficha'>Subí tu video y envía la ficha técnica por mail a contacto@somosprotagonistas.com.ar para completar tu participación en esta fase final.</div> */}

                  {videoEtapa3?.reason_etapa3?.length ?
                  <div className='box_rejected'>
                    {!videoEtapa3.arrpove_etapa3 && videoEtapa3.reason_etapa3 === "especificaciones" ? "No cumple con las especificaciones técnicas." : ""}
                    {!videoEtapa3.arrpove_etapa3 && videoEtapa3.reason_etapa3 === "consigna" ? "No cumple con la consigna." : ""}
                    {!videoEtapa3.arrpove_etapa3 && videoEtapa3.reason_etapa3 === "ambas" ? "No cumple con las especificaciones técnicas ni con la consigna indicada." : ""}
                  </div>
                  : "" }

                  {videoEtapa3.arrpove_etapa3 ? 
                    <div className='box_approved'>Tu video fue aprobado</div>
                  : "" }

                  <div className='box_video_guide_etapa1'>
                    <ReactPlayer
                      ref={_player}
                      className={`react-player`}
                      playing={false}
                      url={`https://vimeo.com/${videoEtapa3.video_id}`}
                      width='100%'
                      height='100%'
                      controls={true}
                      onProgress={(progress) => { !consignaEtapa3 && handleVideoProgress(progress, "etapa3")}}
                      onReady={handleOnReady}
                      onPlay={handleVideoPlay}
                      onStart={handleVideoStart}
                      onStop={handleVideoStop}
                      onEnded={handleVideoEnded}
                      onSeek={handleVideoSeek}
                      config={{ 
                        file: {
                          attributes: {
                            controlsList: 'nodownload'  //<- this is the important bit
                          }
                        },
                        vimeo: {
                          playerOptions: {
                            controls: true,
                            interactive_markers: false,
                            interactive_params: false,
                            vimeo_logo: false,
                            play_button_position: "center",
                            progress_bar: true,
                            title: false,
                            //responsive: true
                          }
                        }
                      }}
                    />
                  </div>
                  <div className='aclaration'>Si no puedes visualizar el video, es posible que el proceso de optimización esté en curso y pueda tardar <span className='highlight'>unas horas</span>. Si después de ese tiempo aún no puedes verlo, por favor contáctanos a <span className='highlight'>contacto@somosprotagonistas.com.ar</span>.</div>

                </div>
              : "" }

              {(!startUploading && selectedVideo) ?
              <div className='file_data'>
                <div className='name'>
                  <div className='label'>Nombre del archivo:</div>
                  <div className='value'>{fileName}</div>
                </div>
                <div className='size'>
                  <div className='label'>Peso del archivo:</div>
                  <div className='value'>{fileSize}</div>
                </div>
              </div> 
              : ""}

              {(!startUploading && selectedVideo) ?
              <div onClick={() => handleUpload("etapa3")} className={`bt_gray bt_upload_file${!selectedVideo ? " disabled" : "" }`}>
                <p>SUBIR VIDEO</p>
              </div>
              : ""}

              {startUploading ?
              <div className='data_progress'>
                <div className='progress_text'>
                  <div className='label'>Progreso de subida:</div>
                  <div className='value'>{uploadProgress}%</div>
                </div>
                
                <div className='video_upload_response'>{uploadStatus}</div>

                {isLoadingVideo ? <div className='loaging'><CircularProgress size={25} /></div> : ""}
              </div> : ""}

            </div>
          </div>

          

        </div>
        : "" : ""}






      {/* {!showPresentation && user.role === "experto" && scope !== "vote" ?
        user.is_barista ?
        <div className='box_upload_video_etapa1'>
          <div className='upload_video_text'>¡Bienvenidos Expertos a la Copa Barista 2024!</div>

          <div className='actions'>
            <div className='bt_gray bt_instructivo' onClick={() => handleDownloadPDF("instructivo")}>
              <p>Instructivo</p>
            </div>
            <div className='bt_skyblue bt_reglamento' onClick={() => handleDownloadPDF("reglamento")}>
              <p>Reglamento</p>
            </div>
            <div className='bt_gray bt_faq' onClick={() => handleDownloadPDF("faq")}>
              <p>Preguntas Frecuentes</p>
            </div>
          </div>

          <div className='inner'>

            {!conditionsViewedEtapa1 && videoEtapa1Upload === true ?
            <div className='video_block'>
              <div className='bt_condiciones' onClick={() => handleDownloadPDF("condiciones")}>¡Preparate leyendo las condiciones para armar tu video!</div>
            </div> : ""}

            {!videoEtapa1 ?
            <>
            <div className='box_video_guide_etapa1'>
              <ReactPlayer
                ref={_player}
                className={`react-player`}
                playing={false}
                url={"https://vimeo.com/1001437876"}
                width='100%'
                height='100%'
                controls={true}
                onProgress={(progress) => { !consignaEtapa1 && handleVideoProgress(progress, "etapa1")}}
                onReady={handleOnReady}
                onPlay={handleVideoPlay}
                onStart={handleVideoStart}
                onStop={handleVideoStop}
                onEnded={handleVideoEnded}
                onSeek={handleVideoSeek}
                config={{ 
                  file: {
                    attributes: {
                      controlsList: 'nodownload'  //<- this is the important bit
                    }
                  },
                  vimeo: {
                    playerOptions: {
                      controls: true,
                      interactive_markers: false,
                      interactive_params: false,
                      vimeo_logo: false,
                      play_button_position: "center",
                      progress_bar: true,
                      title: false,
                      //responsive: true
                    }
                  }
                }}
              />
            </div> 
            </>
            : ""}


            <div className='box_uploader'>
              
              {videoEtapa1Upload === false ? <>La etapa de participación ha finalizado</> : ""}

              {(!startUploading && !videoEtapa1 && videoEtapa1Upload) ? 
              <>
              <HiddenInput type="file" name="video" ref={fileInputRef} onChange={handleVideoChange} />
              <div onClick={() => {consignaEtapa1 && handleButtonBrowserClick()}} className={`bt_skyblue bt_select_file${!consignaEtapa1 ? " disabled" : ""}`}>
                <p>SELECCIONAR VIDEO</p>
              </div>
              </>
              : ""}

              {errorVideosize.length ? <div className='error_video_size'>{errorVideosize}</div> : ""}

              {videoEtapa1 ?
                <div className='box_video_loaded1'>
                  <div className='title'>Video Fase 1 publicado</div>
                  <div className='date'>{moment(videoEtapa1.created_at).format("DD/MM/yyyy HH:mm:ss")}</div>
                  

                  {videoEtapa1?.reason_etapa1?.length ?
                  <div className='box_rejected'>
                    {!videoEtapa1.arrpove_etapa1 && videoEtapa1.reason_etapa1 === "especificaciones" ? "No cumple con las especificaciones técnicas." : ""}
                    {!videoEtapa1.arrpove_etapa1 && videoEtapa1.reason_etapa1 === "consigna" ? "No cumple con la consigna." : ""}
                    {!videoEtapa1.arrpove_etapa1 && videoEtapa1.reason_etapa1 === "ambas" ? "No cumple con las especificaciones técnicas ni con la consigna indicada." : ""}
                  </div>
                  :
                  !videoEtapa1.arrpove_etapa1 ?
                  <div className='text_pending'>En espera de validación para tu participación. A partir del 11 de septiembre sabrás el estado de tu video.</div> : ""
                  }

                  {videoEtapa1.arrpove_etapa1 ? 
                    <div className='box_approved'>Tu video fue aprobado</div>
                  : "" }

                  <div className='box_video_guide_etapa1'>
                    <ReactPlayer
                      ref={_player}
                      className={`react-player`}
                      playing={false}
                      url={`https://vimeo.com/${videoEtapa1.video_id}`}
                      width='100%'
                      height='100%'
                      controls={true}
                      onProgress={(progress) => { !consignaEtapa1 && handleVideoProgress(progress, "etapa1")}}
                      onReady={handleOnReady}
                      onPlay={handleVideoPlay}
                      onStart={handleVideoStart}
                      onStop={handleVideoStop}
                      onEnded={handleVideoEnded}
                      onSeek={handleVideoSeek}
                      config={{ 
                        file: {
                          attributes: {
                            controlsList: 'nodownload'  //<- this is the important bit
                          }
                        },
                        vimeo: {
                          playerOptions: {
                            controls: true,
                            interactive_markers: false,
                            interactive_params: false,
                            vimeo_logo: false,
                            play_button_position: "center",
                            progress_bar: true,
                            title: false,
                            //responsive: true
                          }
                        }
                      }}
                    />
                  </div>
                  <div className='aclaration'>Si no puedes visualizar el video, es posible que el proceso de optimización esté en curso y pueda tardar <span className='highlight'>unas horas</span>. Si después de ese tiempo aún no puedes verlo, por favor contáctanos a <span className='highlight'>contacto@somosprotagonistas.com.ar</span>.</div>

                </div>
              : "" }

              {(!startUploading && selectedVideo) ?
              <div className='file_data'>
                <div className='name'>
                  <div className='label'>Nombre del archivo:</div>
                  <div className='value'>{fileName}</div>
                </div>
                <div className='size'>
                  <div className='label'>Peso del archivo:</div>
                  <div className='value'>{fileSize}</div>
                </div>
              </div> 
              : ""}

              {(!startUploading && selectedVideo) ?
              <div onClick={() => handleUpload("etapa1")} className={`bt_gray bt_upload_file${!selectedVideo ? " disabled" : "" }`}>
                <p>SUBIR VIDEO</p>
              </div>
              : ""}

              {startUploading ?
              <div className='data_progress'>
                <div className='progress_text'>
                  <div className='label'>Progreso de subida:</div>
                  <div className='value'>{uploadProgress}%</div>
                </div>
                
                <div className='video_upload_response'>{uploadStatus}</div>

                {isLoadingVideo ? <div className='loaging'><CircularProgress size={25} /></div> : ""}
              </div> : ""}

            </div>
          </div>

          

        </div>
        :
        <div className='box_voting_pending'>
          <div className='inner'>
            <div className='text'>
              Próximamente vas a poder votar a tu Barista favorito
            </div>
          </div>
        </div>
      : ""} */}
    </WrappedStyled>
  );
};

export default BaristasContainer;